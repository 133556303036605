import React, { useState, useCallback } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function Home() {
  return (
    <div className="container-fluid vh-100  m-0 p-0">
      <Header />
      <div className="container-fluid">
        <div>hello</div>
      </div>
      <Footer />
    </div>
  );
}
