export const AuthenticationReducer = (state, action) => {
  let stateUpdate = {};
  switch (action.type) {
    case "LOGIN":
      //console.log("auth update:", action.data.token);
      localStorage.setItem("person", JSON.stringify({ isAuthenticated: true }));
      localStorage.setItem("token", JSON.stringify(action.data.token));
      stateUpdate = {
        token: action.data.token,
        person: { isAuthenticated: true },
      };
      return stateUpdate;

    case "LOGOUT":
      stateUpdate = {
        token: "",
        person: {
          id: "",
          displayName: "",
          firstName: "",
          surname: "",
          department: "",
          contactNumber: "",
          emailAddress: "",
          username: "",
          isAdmin: "",
          memberOf: null,
          distinguishedName: "",
          isAuthenticated: false,
        },
      };
      localStorage.clear();
      return stateUpdate;

    default:
      return state;
  }
};
