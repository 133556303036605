import { useTable } from "react-table";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Axios from "axios";

function DataTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{ paddingLeft: "15px", paddingRight: "15px" }}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ExcelReader({ file, invoiceNumber }) {
  const [tables, setTables] = useState([]);

  useEffect(() => {
    if (file) readExcel(file);
  }, []);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = async (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const dataPerSheet = await Promise.all(
          wb.SheetNames.map(async (name) => {
            const sheet = wb.Sheets[name];
            let data = XLSX.utils.sheet_to_json(sheet);

            console.log(`Data from sheet "${name}":`, data);

            if (!data || !Array.isArray(data) || data.length === 0) {
              console.warn(
                `Sheet "${name}" is empty or could not be processed.`
              );
              return null;
            } else if (!data[0] || typeof data[0] !== "object") {
              console.error(
                `First row in sheet "${name}" is not an object. Actual data:`,
                data[0]
              );
              return null;
            }

            // Send API request for each row and wait for all to complete
            data = await Promise.all(
              data.map(async (row) => {
                var inData = {
                  jobNo: row.JobNo,
                  invoiceNo: invoiceNumber,
                  db: row.DataBase,
                };
                try {
                  const response = await Axios.post("/api/Defray", inData);
                  return {
                    ...row,
                    "Invoice No": invoiceNumber,
                    "Job ID": response.data.id,
                    Status: response.data.status,
                    Result: response.data.result,
                  };
                } catch (err) {
                  console.log("API Call error : ", err);
                  return row; // Ensure you return the original row in case of an error.
                }
              })
            );

            // creating dynamic columns
            const columns = [
              ...Object.keys(data[0]).map((key) => ({
                Header: key,
                accessor: key,
              })),
            ];

            return { name, columns, data };
          })
        );

        resolve(dataPerSheet);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((dataPerSheet) => {
      setTables(dataPerSheet);
    });
  };

  return (
    <div>
      {tables.map((table, index) => (
        <div key={index}>
          <h2>{table.name}</h2>
          {table.data && (
            <DataTable columns={table.columns} data={table.data} />
          )}
        </div>
      ))}
    </div>
  );
}

export default ExcelReader;
