import React, { useContext } from "react";
import Logo from "../../assets/images/tg-logo.png";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import Navbar from "../../components/navbar/Index";

export default function Header() {
  const { person } = useContext(AuthenticationContext);
  return (
    <div id="header" className="d-flex flex-row-reverse fixed-top">
      <img src={Logo} alt="logo" />
      <Navbar />
    </div>
  );
}
