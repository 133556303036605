import React, { useContext, useState, useEffect } from "react";
import Home from "../../pages/Home/Home";
import Logo from "../../assets/images/tg-logo.png";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import Axios from "axios";
import "./login.scss";

export default function Login() {
  const { person, dispatch } = useContext(AuthenticationContext);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ isError: false, message: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    const data = {
      username: emailAddress,
      password: password,
    };
    Axios.post("/api/Authentication", data)
      .then((res) => {
        //console.log(res.data);
        dispatch({
          type: "LOGIN",
          data: res.data,
        });
        window.location = "/";
      })
      .catch((err) => {
        console.log("API Call error : ", err);
        if (err.response.status === 401) {
          setError({
            isError: true,
            message: "Unauthorised.Please check username/password",
          });
        }
      });

    setPassword("");
    setEmailAddress("");
  };
  useEffect(() => {
    //console.log(localStorage.getItem("token"));
    if (localStorage.getItem("isAuthenticated") !== null) {
      localStorage.setItem(
        "isAuthenticated",
        JSON.stringify("isAuthenticated")
      );
    }
  }, [person]);

  if (!person.isAuthenticated) {
    return (
      <div className="login-container">
        <div className="login-title">
          <img src={Logo} className="login-logo" alt="Logo" />
          <p>Welcome to Technologia Group</p>
          <p>Kratos Management Portal</p>
          <p>Fill in your details below to log in. </p>
        </div>
        <div className="login-form">
          <div className="login-error">
            <div className={error.isError ? "alert alert-danger" : "d-none"}>
              {error.message}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                User Name
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="emailHelp"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <div id="emailHelp" className="form-text">
                This is your Kratos Login
              </div>
            </div>
            <div className="mb-3">
              <div className="col-auto">
                <label htmlFor="password" className="col-form-label">
                  Password
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="password"
                  id="inputPassword6"
                  name="password"
                  className="form-control"
                  aria-describedby="passwordHelpInline"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="col-auto">
                <span id="passwordHelpInline" className="form-text">
                  The password you use to login into Kratos
                </span>
              </div>
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return <Home />;
  }
}
