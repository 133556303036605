import React, { useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./index.scss";
import ExcelReader from "../../components/ExecelReader/ExcelReader";

export default function Index() {
  const [file, setFile] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleFileChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
  };

  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <div className="container-fluid vh-100  m-0 p-0">
      <Header />
      <div className="container-fluid dataForm">
        {!submitted ? (
          <div className="title">
            <h3>Please complete form to defray units</h3>
            <form onSubmit={handleSubmit} className="form">
              <div className="row mb-1 p-2">
                <div className="col form-element">
                  <label>Invoice Number:</label>
                  <input
                    type="text"
                    value={invoiceNumber}
                    onChange={handleInvoiceNumberChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-1 p-2">
                <div className="col form-element">
                  <label>File: </label>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-1 p-2">
                <div className="col form-element">
                  <input type="submit" value="Process" />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <ExcelReader file={file} invoiceNumber={invoiceNumber} />
        )}
      </div>
      <Footer />
    </div>
  );
}
