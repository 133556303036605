import './scss/App.scss';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import Axios from 'axios';
import { AuthenticationContext } from './contexts/AuthenticationContext';
import Home from './pages/Home/Home';
import Defray from './pages/Defray/Index';

export default function App() {
  
  const uri = process.env.REACT_APP_API_HOST.replace(/['"]+/g, '')
  //console.log(uri);
  
  Axios.defaults.baseURL = uri
  let token = null;

  if(localStorage.getItem('token')!= null){
    token  = localStorage.getItem('token').replace(/['"]+/g, '')
  }
  
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  //console.log('Bearer ' +localStorage.getItem('token'))

  const authContext = useContext(AuthenticationContext);
  console.log(authContext)
  if (!authContext.person.isAuthenticated) {
    return <Login />
  } else {
    return (
      <div className='root'>
        <Routes>
          <Route path='/' element={<Home/>} exact  />
          <Route path='/defray' element={<Defray/>} exact  />
        </Routes>
      </div>
    );
  }
}

