import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom"; 
import AuthenticationContextProvider from './contexts/AuthenticationContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthenticationContextProvider>
        <Router>
          <App />
        </Router>
    </AuthenticationContextProvider>
  </React.StrictMode>
);

